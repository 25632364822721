import EmptyState from '@aurora/shared-client/components/common/EmptyState/EmptyState';
import type { ListGroupTypeAndProps } from '@aurora/shared-client/components/common/List';
import { ListVariant } from '@aurora/shared-client/components/common/List/enums';
import { PagerVariant } from '@aurora/shared-client/components/common/Pager/enums';
import type { PagerSeeAllModalTypeAndProps } from '@aurora/shared-client/components/common/Pager/types';
import { PanelType } from '@aurora/shared-client/components/common/Panel/enums';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import topTagsQuery from '@aurora/shared-client/components/tags/TopTags.query.graphql';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import { ModalSize } from '@aurora/shared-client/types/enums';
import type {
  TagConnection,
  TopTagConstraints
} from '@aurora/shared-generated/types/graphql-schema-types';
import { SortDirection } from '@aurora/shared-generated/types/graphql-schema-types';
import type {
  TagViewFragment,
  TopTagsQuery,
  TopTagsQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { TextAlignment } from '@aurora/shared-types/texts/enums';
import {
  merge,
  UndefinedValueMergeBehavior
} from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import React, { useContext, useEffect } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { ItemType, TagInlineMetadata, TagViewVariant } from '../../../types/enums';
import PaneledItemList from '../../common/List/PaneledItemList/PaneledItemList';
import EditableWidget from '../../common/Widget/EditableWidget';
import type { WidgetFC } from '../../common/Widget/types';
import type { ItemViewTypeAndProps } from '../../entities/types';
import useTranslation from '../../useTranslation';
import { TagType } from '../enums';
import localStyles from './TagWidget.module.pcss';
import type { TagWidgetProps } from './types';

const defaultProps: TagWidgetProps = {
  viewVariant: {
    type: TagViewVariant.INLINE,
    props: {
      inlineMetadata: TagInlineMetadata.POST_COUNT,
      usePostCount: true,
      useLastActivityTime: false,
      useIcon: false
    }
  },
  panelType: PanelType.STANDARD,
  useTitle: true,
  pageSize: 10,
  tagType: TagType.ALL,
  sorts: {
    postCount: {
      direction: SortDirection.Desc
    }
  },
  hideIfEmpty: false,
  pagerVariant: {
    type: PagerVariant.SEE_ALL_MODAL
  },
  leadIcon: 'none'
};

export function getFinalProps(props: TagWidgetProps): TagWidgetProps {
  return merge(defaultProps, props, {
    undefinedMergeBehavior: UndefinedValueMergeBehavior.IGNORE_BEFORE_MERGE,
    mergeNested: false
  });
}

/**
 * This component is used to render a list of tags
 * @author Amit Agrawal
 */
const TagWidget: WidgetFC<TagWidgetProps> = props => {
  const { isVisible, ...rest } = props;
  const finalProps: TagWidgetProps = getFinalProps(rest);
  const {
    className,
    viewVariant,
    listVariant,
    panelType,
    useTitle,
    pageSize,
    tagType,
    sorts,
    hideIfEmpty,
    pagerVariant
  } = finalProps;

  const cx = useClassNameMapper(localStyles);
  const i18n = useTranslation(EndUserComponent.TAG_WIDGET);
  const { formatMessage, loading: textLoading } = i18n;
  const { contextNode } = useContext(AppContext);
  const title = formatMessage('title');
  const useEmpty = !hideIfEmpty;
  const modalPageSize = 15;

  let constraints: TopTagConstraints;

  if (tagType === TagType.PRESET_ONLY) {
    constraints = {
      predefinedOnly: {
        eq: true
      }
    };
  }

  const variables: TopTagsQueryVariables = {
    constraints,
    id: contextNode.id,
    first: pageSize,
    sorts
  };

  const queryResult = useQueryWithTracing<TopTagsQuery, TopTagsQueryVariables>(
    module,
    topTagsQuery,
    {
      variables
    }
  );

  const { data: queryData, loading } = queryResult;

  useEffect(() => {
    if (!useEmpty && !loading && !textLoading) {
      isVisible(queryData?.coreNode?.topTags?.totalCount !== 0);
    }
  }, [loading, queryData, isVisible, useEmpty, textLoading]);

  const tagsCount = queryData?.coreNode?.topTags?.totalCount;

  /**
   * function to render header for top tag list
   */
  function renderHeader(): React.ReactElement {
    return <h3 className={cx('lia-list-header')}>{title}</h3>;
  }

  /**
   * A callback function to render Empty State when entity list has not tags
   */
  function renderEmptyState(): React.ReactElement {
    return (
      <EmptyState alignment={TextAlignment.LEFT} description={formatMessage('emptyDescription')} />
    );
  }

  /**
   * Pager to show all variant
   */
  const pagerShowAllVariant: PagerSeeAllModalTypeAndProps<TopTagsQuery, TopTagsQueryVariables> = {
    type: PagerVariant.SEE_ALL_MODAL,
    props: {
      modalClassName: 'lia-g-mb-0 lia-g-pb-20 lia-g-infinite-scroll-modal',
      modalTitle: () => <>{formatMessage('modalTitle', { tagsCount })}</>,
      modalSize: ModalSize.SM,
      queryAndOptions: {
        query: topTagsQuery,
        options: {
          variables: {
            ...variables,
            first: modalPageSize
          }
        },
        searchQueryVariables: searchText => {
          return { constraints: { text: { eq: searchText } } } as TopTagsQueryVariables;
        }
      },
      modalContent: (modalQueryResult, modalBodyReference) => {
        const modalListVariant: ListGroupTypeAndProps<TagViewFragment> = {
          type: ListVariant.LIST_GROUP,
          props: {
            listItemClassName: (): string => cx('lia-g-pl-0')
          }
        };
        const modalPagerVariant = {
          type: PagerVariant.INFINITE_SCROLL,
          props: { rootReference: modalBodyReference }
        };

        const modalViewVariant: ItemViewTypeAndProps<ItemType.TAG, TagViewVariant.INLINE> = {
          type: TagViewVariant.INLINE,
          props: {
            inlineMetadata: TagInlineMetadata.NONE
          }
        };
        return (
          <PaneledItemList<
            TagViewFragment,
            ItemType.TAG,
            ItemViewTypeAndProps<ItemType.TAG, TagViewVariant>,
            TopTagsQuery,
            TopTagsQueryVariables
          >
            type={ItemType.TAG}
            variant={modalViewVariant}
            queryResult={modalQueryResult}
            listVariant={modalListVariant}
            itemPath="coreNode.topTags"
            empty={renderEmptyState}
            pageSize={modalPageSize}
            useEmpty
            pagerVariant={modalPagerVariant}
            onUpdate={(tagConnection: TagConnection): TopTagsQuery => {
              return {
                coreNode: {
                  __typename: 'Category',
                  id: contextNode.id,
                  topTags: tagConnection
                }
              };
            }}
            className={cx(
              'lia-g-pr-0 lia-g-will-load',
              queryResult?.loading ? 'lia-g-is-reloading' : 'lia-g-is-loaded'
            )}
            bodyClassName={cx('lia-g-p-0')}
          />
        );
      },
      useSearchField: true,
      searchFieldPlaceholder: formatMessage('searchFilterPlaceHolder')
    }
  };

  return (
    <EditableWidget<TagWidgetProps> props={finalProps}>
      <PaneledItemList<
        TagViewFragment,
        ItemType.TAG,
        ItemViewTypeAndProps<ItemType.TAG, TagViewVariant>,
        TopTagsQuery,
        TopTagsQueryVariables
      >
        type={ItemType.TAG}
        variant={viewVariant}
        queryResult={queryResult}
        pageSize={pageSize}
        listVariant={listVariant}
        onUpdate={(tagConnection: TagConnection): TopTagsQuery => {
          return {
            coreNode: {
              __typename: 'Category',
              id: contextNode.id,
              topTags: tagConnection
            }
          };
        }}
        itemPath="coreNode.topTags"
        empty={renderEmptyState}
        panel={panelType}
        className={cx(className)}
        bodyClassName={cx({ 'lia-list-body': viewVariant.type === TagViewVariant.CHIP })}
        data-testid="tagList"
        header={useTitle && renderHeader}
        headerClassName={cx('lia-g-pb-25')}
        useFooter={pagerVariant.type !== PagerVariant.NONE}
        pagerVariant={
          pagerVariant.type === PagerVariant.SEE_ALL_MODAL ? pagerShowAllVariant : pagerVariant
        }
        useEmpty
      />
    </EditableWidget>
  );
};

export default TagWidget;
